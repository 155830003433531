import React from "react"

import SEO from "../components/seo"

const Results = (props) => {
  const block = {
    __typename: "WpPage_Sections_Sections_SingCardsBlock",
    fieldGroupName : "page_Sections_Sections_SingCardsBlock",
    singCards: {
      fieldGroupName: 'sing_cards',
      heading: null,
      style: "carousel",
      subheading: null,
      vibemapSuggests : null,
      posts:[{
        type: "list",
        vibeQuery: "chill",
        subcategory: null,
        geoQuery: {
          latitude: 37.8043514,
          longitude: -122.2711639
        },
        categoryQuery: null,
        distanceQuery: 60,
        fieldGroupName: "posts",
        city: null
      }]
    },
    overrideQuery: {
      // Important: Pass override vibes
      distance: props.distance,
      location: props.location,
      vibe: props.vibes
    },
    name: "acf-sing-cards"
  }

  return (
    <div>
      <SEO
        data={{
          title: `404: Not found`,
        }}
      />
      <div
        className="container has-text-centered"
        style={{ padding: `24rem 0 0` }}
      >
        <h1>Cards Loading</h1>
        <p>The page requested was not found.</p>

      </div>
    </div>
  )
}

export default Results
